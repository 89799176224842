var width = document.documentElement.clientWidth;

export const surveyJson305 = {
  title: "GRI 305：能源 2016",
  logoPosition: "right",
  pages: [
    {
      name: "305-1",
      elements: [
        {
          type: "panel",
          name: "305-1-panel",
          elements: [
            {
              type: "text",
              name: "305-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "305-1 直接（範疇一）溫室氣體排放",
    },
    {
      name: "305-2",
      elements: [
        {
          type: "panel",
          name: "305-2-panel",
          elements: [
            {
              type: "text",
              name: "305-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "305-2 能源間接（範疇二）溫室氣體排放",
    },
    {
      name: "305-3",
      elements: [
        {
          type: "panel",
          name: "305-3-panel",
          elements: [
            {
              type: "text",
              name: "305-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "305-3 其它間接（範疇三）溫室氣體排放",
    },
    {
      name: "305-4",
      elements: [
        {
          type: "panel",
          name: "305-4-panel",
          elements: [
            {
              type: "text",
              name: "305-4",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "305-4 溫室氣體排放密集度",
    },
    {
      name: "305-5",
      elements: [
        {
          type: "panel",
          name: "305-5-panel",
          elements: [
            {
              type: "text",
              name: "305-5",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "305-5 溫室氣體排放減量",
    },
    {
      name: "305-6",
      elements: [
        {
          type: "panel",
          name: "305-6-panel",
          elements: [
            {
              type: "text",
              name: "305-6",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "305-6 破壞臭氧層物質的排放",
    },
    {
      name: "305-7",
      elements: [
        {
          type: "panel",
          name: "305-7-panel",
          elements: [
            {
              type: "text",
              name: "305-7",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "305-7 氮氧化物(NOx)、硫氧化物(SOx)，及其它重大的氣體排放",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
