import React from "react";
import {
  Box,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  Grid,
} from "@mui/material";

import { useSetIndustry } from "state/app/hooks";
import PicTitle from "images/logo.svg";
import Icon1 from "images/icon_index_btn01.png";
import Icon2 from "images/icon_index_btn02.png";
import Icon3 from "images/icon_index_btn03.png";
import Icon4 from "images/icon_index_btn04.png";
import Icon5 from "images/icon_index_btn05.png";
// import Icon6 from "images/icon_index_btn06.png";
import Icon7 from "images/icon_index_btn07.png";
import Icon8 from "images/icon_index_btn08.png";
// import Icon9 from "images/icon_index_btn09.png";
import Icon10 from "images/icon_index_btn10.png";
import Icon11 from "images/icon_index_btn11.png";
import Icon12 from "images/icon_index_btn12.png";
import Icon13 from "images/icon_index_btn13.png";
import Icon14 from "images/icon_index_btn14.png";
import Icon15 from "images/icon_index_btn15.png";
import Icon01 from "images/icons/icon_01.png";
// import Icon02 from "images/icons/icon_02.png";
import Icon03 from "images/icons/icon_03.png";
import Icon04 from "images/icons/icon_04.png";
import Icon05 from "images/icons/icon_05.png";
import Icon06 from "images/icons/icon_06.png";
import Icon07 from "images/icons/icon_07.png";
// import Icon08 from "images/icons/icon_08.png";
import Icon09 from "images/icons/icon_09.png";
import Icon010 from "images/icons/icon_10.png";
import Icon011 from "images/icons/icon_11.png";
import Icon012 from "images/icons/icon_12.png";
import Icon013 from "images/icons/icon_13.png";
// import Icon014 from "images/icons/icon_14.png";
import Icon015 from "images/icons/icon_15.png";
// import Icon016 from "images/icons/icon_16.png";
import Icon017 from "images/icons/icon_17.png";
import Icon018 from "images/icons/icon_18.png";
import Icon019 from "images/icons/icon_19.png";
import Icon020 from "images/icons/icon_20.png";
import Icon021 from "images/icons/icon_21.png";
import Icon022 from "images/icons/icon_22.png";
import Icon023 from "images/icons/icon_23.png";
// import Icon024 from "images/icons/icon_24.png";
// import Icon025 from "images/icons/icon_25.png";
import Icon026 from "images/icons/icon_26.png";
import Icon027 from "images/icons/icon_27.png";
import Icon028 from "images/icons/icon_28.png";
import Icon029 from "images/icons/icon_29.png";
import Icon030 from "images/icons/icon_30.png";
import Icon031 from "images/icons/icon_31.png";
import Icon032 from "images/icons/icon_32.png";
import Icon033 from "images/icons/icon_33.png";

export const MenuDialog = ({ openDialog, setOpenDialog }) => {
  const setIndustry = useSetIndustry();
  const menu = [
    {
      label: "食品業",
      src: Icon1,
      alt: "Star1",
      isDisabled: true,
    },
    {
      label: "建築業",
      src: Icon2,
      alt: "Star2",
      isDisabled: true,
    },
    {
      label: "運輸業",
      src: Icon3,
      alt: "Star3",
      isDisabled: true,
    },
    {
      label: "電子業",
      src: Icon4,
      alt: "Star4",
      isDisabled: true,
    },
    {
      label: "金融業",
      src: Icon5,
      alt: "Star4",
      isDisabled: true,
    },
  ];

  const menu2 = [
    {
      label: "生技業",
      src: Icon11,
      alt: "Star1",
      isDisabled: false,
    },
    {
      label: "觀光餐旅業",
      src: Icon10,
      alt: "Star4",
      isDisabled: false,
    },
    {
      label: "醫療業",
      src: Icon13,
      alt: "Star2",
      isDisabled: false,
    },
    {
      label: "院校產業",
      src: Icon14,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "農會產業",
      src: Icon15,
      alt: "Star4",
      isDisabled: false,
    },
  ];

  const menu3 = [
    {
      label: "報告書",
      src: Icon12,
      alt: "Star1",
      isDisabled: false,
    },
    {
      label: "半導體業",
      src: Icon7,
      alt: "Star2",
      isDisabled: false,
    },
    {
      label: "光電業",
      src: Icon8,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "水泥工業",
      src: Icon01,
      alt: "Star4",
      isDisabled: false,
    },
    {
      label: "塑膠工業",
      src: Icon03,
      alt: "Star4",
      isDisabled: false,
    },
  ];

  const menu4 = [
    {
      label: "紡織纖維",
      src: Icon04,
      alt: "Star1",
      isDisabled: false,
    },
    {
      label: "電機機械",
      src: Icon05,
      alt: "Star2",
      isDisabled: false,
    },
    {
      label: "電器電纜",
      src: Icon06,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "化學工業",
      src: Icon07,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "玻璃陶瓷",
      src: Icon09,
      alt: "Star4",
      isDisabled: false,
    },
  ];

  const menu5 = [
    {
      label: "造紙工業",
      src: Icon010,
      alt: "Star5",
      isDisabled: false,
    },
    {
      label: "鋼鐵工業",
      src: Icon011,
      alt: "Star1",
      isDisabled: false,
    },
    {
      label: "橡膠工業",
      src: Icon012,
      alt: "Star2",
      isDisabled: false,
    },
    {
      label: "汽車工業",
      src: Icon013,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "電腦及週邊設備業",
      src: Icon015,
      alt: "Star4",
      isDisabled: false,
    },
  ];

  const menu6 = [
    {
      label: "通信網路業",
      src: Icon017,
      alt: "Star5",
      isDisabled: false,
    },
    {
      label: "電子零組件業",
      src: Icon018,
      alt: "Star1",
      isDisabled: false,
    },
    {
      label: "電子通路業",
      src: Icon019,
      alt: "Star2",
      isDisabled: false,
    },
    {
      label: "資訊服務業",
      src: Icon020,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "其他電子業",
      src: Icon021,
      alt: "Star4",
      isDisabled: false,
    },
  ];

  const menu7 = [
    {
      label: "建材營造",
      src: Icon022,
      alt: "Star5",
      isDisabled: false,
    },
    {
      label: "航運業",
      src: Icon023,
      alt: "Star1",
      isDisabled: false,
    },
    {
      label: "貿易百貨",
      src: Icon026,
      alt: "Star2",
      isDisabled: false,
    },
    {
      label: "油電燃氣業",
      src: Icon027,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "綜合",
      src: Icon028,
      alt: "Star4",
      isDisabled: false,
    },
  ];

  const menu8 = [
    {
      label: "綠能環保",
      src: Icon029,
      alt: "Star5",
      isDisabled: false,
    },
    {
      label: "數位雲端",
      src: Icon030,
      alt: "Star1",
      isDisabled: false,
    },
    {
      label: "運動休閒",
      src: Icon031,
      alt: "Star2",
      isDisabled: false,
    },
    {
      label: "居家生活",
      src: Icon032,
      alt: "Star3",
      isDisabled: false,
    },
    {
      label: "其他",
      src: Icon033,
      alt: "Star3",
      isDisabled: false,
    },
  ];

  const handleClose = (id) => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        // className="z-10"
      >
        <Grid item className="flex justify-center">
          <Grid item xs={12} md={12}>
            <Button>
              <img src={PicTitle} alt="title" />
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ width: "100%", height: 820 }}>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className="text-black "
              />
            ))}
          </BottomNavigation>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu2.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className={
                  "text-black " + (item.isDisabled ? " opacity-50" : "")
                }
                disabled={item.isDisabled}
              />
            ))}
          </BottomNavigation>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu3.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className={
                  "text-black " + (item.isDisabled ? " opacity-50" : "")
                }
                disabled={item.isDisabled}
              />
            ))}
          </BottomNavigation>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu4.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className={
                  "text-black " + (item.isDisabled ? " opacity-50" : "")
                }
                disabled={item.isDisabled}
              />
            ))}
          </BottomNavigation>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu5.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className={
                  "text-black " + (item.isDisabled ? " opacity-50" : "")
                }
                disabled={item.isDisabled}
              />
            ))}
          </BottomNavigation>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu6.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className={
                  "text-black " + (item.isDisabled ? " opacity-50" : "")
                }
                disabled={item.isDisabled}
              />
            ))}
          </BottomNavigation>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu7.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className={
                  "text-black " + (item.isDisabled ? " opacity-50" : "")
                }
                disabled={item.isDisabled}
              />
            ))}
          </BottomNavigation>
          <BottomNavigation
            showLabels
            value="0"
            onChange={(event, newValue) => {
              localStorage.setItem("IndustryName", newValue);
              setIndustry(newValue);
              setOpenDialog(false);
            }}
            className="bg-transparent mt-20 "
          >
            {menu8.map((item, index) => (
              <BottomNavigationAction
                key={`s${index + 1}`}
                label={<span className="text-base">{item.label}</span>}
                value={item.label}
                icon={
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ maxWidth: "115%" }}
                    className="mb-2"
                  />
                }
                className={
                  "text-black " + (item.isDisabled ? " opacity-50" : "")
                }
                disabled={item.isDisabled}
              />
            ))}
          </BottomNavigation>
          <Grid item className="flex justify-center mt-12">
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                className="mt-3 mb-2 border-[#19b394] text-[#19b394] text"
                component="a"
                href="https://esg.numiner.io"
              >
                返回登入
              </Button>
            </Grid>
          </Grid>
          <Box className="h-4"></Box>
        </Box>
      </Dialog>
    </>
  );
};
